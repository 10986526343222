.searchBarContainer {
    position:absolute;
    z-index: 1000;
    border: 1px solid pink;
}

.formLabel {
    font-family: "Luckiest Guy";
    color:white;
}
.searchFormGroup {
    border:1px solid white;
    border-radius: 10px;
}
.tenseSelectors, .langSelectors {
    font-family: "Luckiest Guy";
}

.veronica {
    position: relative;
    display: inline-block;
    padding:-5px;
}

.mainTranslationText {
    font-family: "Luckiest Guy";
    font-size: 1.6em;
    color:white;
    margin-left:1em;
}

.veronica img {
    position:relative;
    max-width: 80px;
    margin-right:10px;
    margin-left:10px;
}

@media only screen and  (max-width: 540px) {
    .btn-group {
        display:block !important;
        width:100% !important;
    }

    .tenseSelectors .btn {
        width:100% !important;
    }

    .btn-success {
        width:300px;
        margin-bottom: 20px;
    }
    .searchFormGroup {
        border:0;
        width:100%;
    }
    .searchFormGroup .formLabel, .tenseSelectorsContainer {
        display:none;
    }
    .veronica {
        background-color: transparent;
        border:0;
    }
    .veronica img {
        width:60px;
    }

    .mainTranslationText {
        width:100%;
        display:block;
        padding:3px;
        color:white;
        background-color: transparent;
        float:right;

    }
}

.copyrightText {
    opacity: .75;
    color:white;
    margin:0 auto;

}
.wordRequested {
    display:inline-block;
    border:0;
    border-bottom:2px solid black;
    font-family: "Luckiest Guy";
}

.searchForm .form-group {
    width:100%;
}
.react-autosuggest__container {
    position: relative;
    width:100%;
}

.react-autosuggest__input {
    float:left;
    font-family: "Luckiest Guy";
    font-size:1.3em;
    border-radius: .7em;
    padding:.5em;
    width:100%;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 54px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: "Luckiest Guy" !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1000001;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

