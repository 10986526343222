.avatarNavbar {
    max-width: 60px;
    max-height: 60px;
    border:3px solid white;
    border-radius: 50%;
    padding:5px;
    background-color: white;
    margin-right: 10px;
    margin-left: 10px;

}

@media only screen and (min-width: 990px) {
    .navSignin {
    position:absolute;
        right:20px
    }
}